import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { channelMessage } from '@portal/arq-portal-core';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    console.log("update", registration);
    registration.unregister().then(() => window.location.reload());
  }
});

window.addEventListener("keydown", function (e) {
  if (e.ctrlKey && (e.code === "KeyP" || e.keyCode === 80)) {
    e.preventDefault();
    const iframe = document.getElementsByName("<__MAINFRAME__>")[0] as HTMLIFrameElement;

    if (iframe?.contentWindow) {
      channelMessage.send({ type: 'PRINT' }, iframe.contentWindow).catch((error) => {
        console.error("Error al enviar el mensaje al iframe:", error);
        window.print();
      }
      );
    }
  }
});