import { ErrorBoundary, PortalSplash } from '@portal/arq-portal-core';
import React, { Suspense } from 'react';

import { SWRConfig } from 'swr';

const PortalApp = React.lazy(() => import('./PortalApp'));

let hay_error = false;

function localStorageProvider() {
  // When initializing, we restore the data from `localStorage` into a map.
  const map = new Map(JSON.parse(localStorage.getItem('app-cache') || '[]'))

  // Before unloading the app, we write back all the data into `localStorage`.
  window.addEventListener('beforeunload', () => {
    if(!hay_error){
      console.log("arq-portal-app", "guardar sesión");
      const appCache = JSON.stringify(Array.from(map.entries()))
      localStorage.setItem('app-cache', appCache)
    }else{
      console.log("arq-portal-app", "eliminar sesión");
      localStorage.removeItem('app-cache');
    }
  })

  // We still use the map for write & read for performance.
  return map
}

function reload(){
  window.location.reload();
}

function App(props: any) {

  return (
    <ErrorBoundary fallback={(error:any)=>{
      console.log("FALLBACK", error);
      hay_error = true;
      localStorage.removeItem('app-cache');
      return <PortalSplash titulo="Gestiona" mensaje={{tipo: "error", titulo: "Ha ocurrido un error al acceder al portal", subtitulo: <div>Actualiza la página o <a href='/' onClick={reload}>pulsa aquí para volver a intentarlo</a></div>}}></PortalSplash>
      }}>
      <SWRConfig value={{ provider: localStorageProvider, shouldRetryOnError: false, errorRetryCount: 0, dedupingInterval: 6000, revalidateIfStale: true, revalidateOnFocus: false, revalidateOnReconnect: false }}>
        <Suspense fallback={null}>
          <PortalApp></PortalApp>
        </Suspense>
      </SWRConfig>
      </ErrorBoundary>

  );
}

export default App;


